<script setup lang="ts">
definePageMeta({
  layout: 'guest',
});

useHead({
  title: 'Wystapił bląd',
});

defineProps<{
  error: {
    statusMessage: string;
  };
}>();

const handleError = () => clearError({ redirect: '/' });
</script>

<template>
  <PageNotFound v-if="error.statusMessage?.includes('Page not found')">
    <PrimeButton rounded size="small" @click="handleError">
      Przejdź do strony głównej
    </PrimeButton>
  </PageNotFound>

  <section
    v-else
    class="bg-surface-section font-inter flex h-screen items-center justify-center"
  >
    <div class="max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
      <div class="mx-auto max-w-screen-sm space-y-6 text-center">
        <h1
          class="text-bluegray-900 mb-4 text-3xl font-bold tracking-tight md:text-4xl"
        >
          {{ error.statusMessage }}
        </h1>

        <p class="text-global-textColor mb-4 text-lg font-light">
          Przepraszamy za niedogodności. Proszę przejść do strony głównej.
        </p>

        <PrimeButton size="small" rounded @click="handleError">
          Przejdź do strony głównej
        </PrimeButton>
      </div>
    </div>
  </section>
</template>
