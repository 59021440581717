<template>
  <section
    class="bg-surface-section flex h-screen items-center justify-center font-inter"
  >
    <div class="max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
      <div class="mx-auto max-w-screen-sm space-y-6 text-center">
        <h1
          class="text-bluegray-900 mb-4 text-3xl font-bold tracking-tight md:text-4xl"
        >
          Ta strona już nie istnieje
        </h1>

        <slot></slot>
      </div>
    </div>
  </section>
</template>
