<script setup lang="ts">
useHead({
  title: 'Insylio',
});
</script>

<template>
  <NuxtErrorBoundary>
    <NuxtLayout class="font-inter">
      <AppVersionDialog />

      <NuxtPage />

      <PrimeToast position="bottom-right" />
      <PrimeConfirmDialog :style="{ width: '430px', 'max-width': '90%' }" />
    </NuxtLayout>
  </NuxtErrorBoundary>
</template>
