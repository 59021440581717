import { useStorage } from '@vueuse/core';

export default defineNuxtPlugin(() => {
  const latestVersionRef = useStorage('latest-version', '');
  const currentVersion = useStorage('app-version', '');

  async function fetchVersion(updateCurrentVersion = false) {
    try {
      const response = await fetch('/version.json');

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = (await response.json()) as { version: string };
      const latestVersion = data.version;

      latestVersionRef.value = latestVersion;

      if (updateCurrentVersion) {
        currentVersion.value = latestVersion;
      }
    } catch (error) {
      console.error('Błąd podczas sprawdzania wersji aplikacji:', error);
    }
  }

  fetchVersion(true);
  setInterval(() => fetchVersion(), 60000);
});
