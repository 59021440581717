<script setup lang="ts">
const { isCurrentVersion, updateVersion } = useAppVersion();
</script>

<template>
  <PrimeDialog
    :visible="!isCurrentVersion"
    modal
    header="Dostępna jest nowa wersja aplikacji"
    :style="{ width: '430px', 'max-width': '90%' }"
    :closable="false"
  >
    <div class="space-y-3 text-center">
      <div class="text-left text-base text-gray-iron-900">
        Odśwież, aby wczytać najnowszą wersję.
      </div>

      <PrimeButton size="small" rounded @click="updateVersion">
        Odśwież teraz
      </PrimeButton>
    </div>
  </PrimeDialog>
</template>
