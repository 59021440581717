import { useStorage } from '@vueuse/core';

export function useAppVersion() {
  const currentVersion = useStorage('latest-version', '');
  const latestVersion = useStorage('app-version', '');

  const isCurrentVersion = computed(
    () => currentVersion.value === latestVersion.value,
  );

  function updateVersion() {
    currentVersion.value = latestVersion.value;
    window.location.reload();
  }

  return { isCurrentVersion, updateVersion };
}
